/**
 * Generate breadcrumb based on the path given
 * Taken/adapted from here https://github.com/intesso/url-breadcrumb/blob/master/index.js
 */
const generateCrumbsFromPath = (url, opts) => {
  // options
  opts = opts || {};
  opts.home = opts.home || "Home";
  opts.endingSlash = opts.endingSlash || false;
  opts.pageTitle = opts.pageTitle || null;
  opts.beautify =
    opts.beautify ||
    function(str) {
      // Take off -, _ and make every first letter capitalized
      return str
        .replace(/[-_]/g, " ")
        .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
    };

  // extract url path (without querystring and without hash)
  const urlPath = url.split(/[?#]/)[0];
  // split segments by slash and filter empty elements
  const segments = urlPath.split("/").filter(Boolean);
  // add first element for `home` element
  segments.unshift("");

  // enhance segments array
  return segments.map((crumb, i, arr) => {
    // join the segments array up to the actual element with slash
    var p = arr.slice(0, i + 1).join("/");
    if (opts.endingSlash) {
      // the last element must get the ending slash back, if it was there originally (lost during split)
      // every higher path segment must get an ending slash.
      if (i < arr.length - 1 || urlPath.endsWith("/")) p += "/";
    } else {
      // the first element must always be a slash
      if (i === 0) p = "/";
    }

    // the first element must get the `home` name, every other element must be uri decoded
    crumb = i === 0 ? opts.home : decodeURIComponent(crumb);

    return {
      name: crumb,
      title: segments.length - 1 === i ? opts.pageTitle : opts.beautify(crumb),
      link: segments.length - 1 === i ? null : p,
    };
  });
};

export default generateCrumbsFromPath;
