import React, { useContext } from "react";
import { AccruentImg, LocalizedLink } from "src/components/commonComponents";
import { capitalize } from "src/utils";

import { StaticImage } from "gatsby-plugin-image";
import generateCrumbs from "src/components/helpers/generateCrumbsFromPath";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
import rewordResourceLink from "src/components/helpers/rewordResourceLink";
import splitBodyContent from "../helpers/splitBodyContent";
const defaultImage = "../../../static/placeholders/articles/ArticleImg1.jpg";

const SearchFeed = ({ searchResult, searchInput }) => {
  const { resetFilter } = useContext(ResourcesContext);

  const titleToDisplay = (node) => {
    if (node?.context?.details) {
      return node.context.details.title;
    }
    if (node?.context?.fieldHeroHeading || node?.context?.title || node?.context?.fieldHeroTitle){
      return node?.context?.fieldHeroHeading || node?.context?.title || node?.context?.fieldHeroTitle
    }
    if (!node.context.title && !node.context.fieldPageTitle) {
      const entity_label = node.context.entityLabel;
      if (entity_label) {
        return entity_label;
      }
      const crumbs = generateCrumbs(node.path).slice(1);
      return crumbs.map((crumb) => crumb.title).join(" - ");
    }

    return node.context.fieldPageTitle;
  };
  const descriptionToDisplay = (node) => {
    return node?.context?.fieldHeroBody?.value || node?.context?.fieldResourceDescription  || node?.context?.body?.value
  }

  return (
    <>
      {searchResult &&
        searchResult.length !== 0 &&
        searchResult.map((result, i) => {
          let typeLabel;
          if (result.node.context.contentTypeId) {
            typeLabel = result.node.context.contentTypeId[0].label
              ?.split(" ")
              .map((str) => capitalize(str))
              .join("");

            if (typeLabel === "BlogEntries") typeLabel = "BlogPosts";
          }

          const imageProps = {
            alt: "Accruent - Search Item",
            className:
              "has-border-radius-medium has-height-100-percent has-object-fit-cover",
          };

          let teaser_image = (
            <StaticImage src={defaultImage} {...imageProps} aspectRatio={1.25} decoding = "async" />
          );

          let heroImage = result?.node?.context?.heroImage || result?.node?.context?.commonHeroProps?.heroImage
          if (heroImage?.gatsbyImage) {
            teaser_image = (
              <AccruentImg
                // aspectRatio: 1.25,
                file={heroImage}
                {...imageProps}
              />
            );
          }


          return (
            <div className="box mb-2 !tw-p-4 tw-bg-accruent_white tw-bg-no-repeat tw-bg-0% tw-bg-origin-padding tw-border tw-border-accruent_gray tw-rounded-2xl" key={i}>
              <div className="tile is-ancestor search-feed-item !tw-min-h-[200px]">
                <div className="tile is-4 is-parent">
                  <div className="tile is-child">
                    <div className="has-height-100-percent">
                      <LocalizedLink to={result.node.path}>
                        {teaser_image}
                      </LocalizedLink>
                    </div>
                  </div>
                </div>

                <div className="tile is-8 is-parent is-align-items-center">
                  <div className="tile is-child">
                    <div className="mb-3">
                      <h4 className="title title-5 card-title tw-break-words !tw-mb-4 !tw-text-xl tw-font-Poppins !tw-leading-[23.2px] !tw-tracking-[0.18px] tw-pb-1">
                        <LocalizedLink to={result.node.path} className="tw-text-accruent_black1 hover:tw-underline">
                          {titleToDisplay(result.node)}
                        </LocalizedLink>
                      </h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: splitBodyContent(descriptionToDisplay(result?.node)?.replace("h1", "p")?.replace("is-size-1-desktop", "").replace("title",""), 1)?.firstContentToString
                        }}
                      />
                    </div>
                    <div className="tags tw-flex">
                      <div className="!tw-text-[18px] tw-font-Roboto">
                        {result.node.context.industryIds &&
                          result.node.context.industryIds.filter(({label})=>!!label).map(
                            ({ id, label }, index) => {
                              return (
                                <LocalizedLink
                                  to={`/resources?industry=${id}`}
                                  key={id}
                                  handleClick={() => resetFilter()}
                                >
                                  {`#${label}${index !==
                                      result.node.context.industryIds.length - 1
                                      ? ", "
                                      : ""
                                    }`}
                                </LocalizedLink>
                              );
                            }
                          )}
                      </div>
                      <div className="!tw-text-[18px] tw-font-Roboto">
                        {result.node.context.solutionIds &&
                          result.node.context.solutionIds.filter(({label})=>!!label).map(
                            ({ id, label }, index) => {
                              return (
                                <LocalizedLink
                                  to={`/resources?solution=${id}`}
                                  key={id}
                                  handleClick={() => resetFilter()}
                                  className="hash-tags"
                                >
                                  {`#${label}${index !==
                                      result.node.context.solutionIds.length - 1
                                      ? ", "
                                      : ""
                                    }`}
                                </LocalizedLink>
                              );
                            }
                          )}
                      </div>
                      {result.node.context.contentTypeId && (
                        <div className="is-size-7 is-capitalized !tw-text-[18px] tw-font-Roboto">
                          <LocalizedLink
                            to={rewordResourceLink(
                              result.node.context.contentTypeId[0].label
                            )}
                            handleClick={() => resetFilter()}
                            className="hash-tags"
                          >
                            #{typeLabel}
                          </LocalizedLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {searchResult && searchResult.length === 0 && (
        <div className="content">
          <h4 className="title title-5 has-text-centered">
            No Search Results
          </h4>
          <p>You could try:</p>
          <ul>
            <li>Make sure all words are spelled correctly</li>
            <li>Try different keywords</li>
            <li>Try more general keywords</li>
          </ul>
        </div>
      )}
    </>
  );
};

export default SearchFeed;
