const rewordResourceLink = (label) => {
  // let newLabel = label
  //   .replace("/", "")
  //   .split(" ")
  //   .map((str) => str.toLowerCase().trim())
  //   .join("-");
  let newLabel = label
    .replace("/", "")
    .replace(/ /g, "-")
    .toLowerCase();
  if (newLabel === "blog-entries") newLabel = "blog-posts";
  if (newLabel === "press-releases--news") newLabel = "press-releases";
  const newLink = "/resources/all-" + newLabel;

  return newLink;
};

export default rewordResourceLink;
